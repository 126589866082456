// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardHeader from './standard-header'
import FixedHeader from './fixed-header'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Header */
const Header = React.memo(
  ({ currentWidth, currentHeight, nav, uri, originalPath }) => (
    <header className="container">
      {currentWidth === 'w-xs' ||
      currentWidth === 'w-sm' ||
      currentWidth === 'w-md' ? (
        <StandardHeader uri={uri} originalPath={originalPath} />
      ) : (
        <Fragment>
          {nav === 'fixed' ? (
            <FixedHeader uri={uri} originalPath={originalPath} />
          ) : (
            <StandardHeader uri={uri} originalPath={originalPath} />
          )}
        </Fragment>
      )}
    </header>
  )
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Header
